:root{
    --yellow: #fbcd00;
    --pink: #f6006d;
    --light-green: #eaeff3;
    /* rgb(195, 11, 42) */
}

.navBg{
    backdrop-filter: blur(3.5px);
    background-color: rgba(48, 45, 45, 0.2);
    width: 100%;
    height: 5rem;
    position: relative;
    z-index: 3;
}

 
nav{
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    height: 100%;
    color: white;
    width: 100%;
}

nav img{
    width: 6rem;
    height: 3rem;
}

nav ul{
    display: inline-flex;
    gap: 15px;
    justify-self: center;
    align-items: center;
    
}

.showDropDownMenu{
    height: auto;
    padding: 2rem 0;
    overflow: visible;
}

nav ul li a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}

nav ul li a:hover{
    color: var(--yellow);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
}

.nav-login-cont{
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav-login-cont div:hover{
    color: var(--yellow);
}


.nav-line{
    width: 1.3px;
    height: 1rem;
    background-color: white;
}

.hide{
    display: none;
}

.nav-login-cont div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5px;
}

/* cart style */
.cart-overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 60%;
    opacity: 0;
    z-index: -10;
    transition: 0.5s all;
}

.cartCont{
    width: 40vw;
    height: 100vh;
    background-color: var(--light-green);
   border-left:2px solid #ddd;
   padding: 1rem;   
   color: black;
}

.cart-header{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-content{
    width: 100%;
    height: 80%;
    overflow: auto;
}


.cart-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    background-color: white;
    margin-top: 1.5rem;
    padding: .9rem;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.cart-row img{
    width: 30%;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 100%;
}

.prod-details{
    width: 50%;
    overflow-y: auto;
    padding:0.5rem 1rem; 
}

.qty-cont{
    width: 10%;
    padding-top:0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qty{
    background-color: var(--light-green);
    color: black;
    padding: .5rem .8rem;
    border-radius: 7px;
}

.delete{
    width: 10%;
    display: flex;
    justify-content: flex-end;
}


.cart-footer{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-footer b{
    font-size: 0.9em;
    color: #959292;
}

#checkoutBtn{
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    background-color: rgb(28, 128, 63);
    color: white;
    font-size: 1.1em;
    transform: translateY(4px);
}

/* cart ends */

#homeLanding{
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/Screenshot_20240508-172138.jpg');
    background-position: center;
    background-size: cover;
    color: white;
    overflow: hidden;
}

.homeCont{
    width: 100%;
    height: 90%;
    padding: 2rem;
    display: flex;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.04);
}

.blur{
    width: 80%;
    padding: 25px 15px;
    height: auto;
    
    border-radius: 8px;
}

.sideA h1{
    font-size: 4em;
}

.sideA{
    width: 50%;
}

.sideA p{
    font-size: 1.1em;
    width: 70%;
    padding-left: .5rem;
    margin: 1.3rem 0;
    letter-spacing: 2px;
}


.sideA button{
    padding: 10px 25px;
    border: 2px solid white;
    border-radius: 7px;
    margin-top: 1rem;
    margin-left: .5rem;
    background-color: transparent;
    color: white;
    letter-spacing: 2px;
}

.searchCont{
    width: 85%;
    height: auto;
    background-color: #eaeff3;
    margin: auto;
    margin-top: -2.5rem;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    backdrop-filter: blur(0px);
    border-radius: 10px;
    
    box-shadow: 0 4px 4px rgba(181, 177, 177, 0.6);
}


.searchCont input{
    width: 100%;
    padding: 13px;
    border-radius: 15px;
    background-color: white;
    border: 2px solid #ddd;
    margin-top: .5rem;
    margin-bottom: .6rem;
}

/* Latest events section */
#Latest-events{
    padding: 10px 3.5rem;
    height: auto;
}

.headerBg{
    width: 8.2rem;
    height: 2.5rem;
    border-radius: 6px;
    background-color: var(--pink);
    position: relative;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    margin-left: 0.7rem;
}

.headerContent{
    width: 8.2rem;
    height: 2.5rem;
    border-radius: 6px;
    background-color: var(--pink);
    border: 1px solid white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: .3rem;
    left: .3rem;
    color: white;
    padding: .5rem;
}


.ticketsContainer{
    width: 100%;
    height: auto;
    margin-top: 1rem;
    display: flex;
    gap: 2.5%;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 0.5rem;
    margin-bottom: auto;
}

.eventCont{
    height: 15rem;
    width: 18%;
    position: relative;
    border-radius: 12px;
    margin-bottom: 1.5rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 6px;
    background-color: white;
}

.eventCont img{
    width: calc(100% + 12px);
    height: 70%;
    border-radius: 12px;
    transform: translate(-6px, -6px);
}

.cartIcon-ticketType-cont{
    position: absolute;
    right: 0.5rem;
    bottom: 20%;
    display: flex;
    align-items: flex-end;
}

.cartIcon{
    width: 2.45rem;
    height: 2.45rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.ticketType{
    background-color: white;
    padding: .5rem;
    padding-right:2rem;
    padding-bottom: 0;
    transform: translate(3rem, -0.6rem); 
    height: 0;
    overflow: hidden;
    font-size: 0.8em;
    border-top-right-radius: 10px;
    transition: 0.5s all;
    cursor: pointer;
    opacity: 0;
}


.showTicket{
    height: auto;
    opacity: 1;
    transition: 0.5s all;
}

.ticket:hover{
   color: var(--pink);
}
.ticket{
    margin-bottom: .3rem;
}

.ticket button{
    border: none;
    background-color: transparent;
    border: 1px solid --light-green;
    font-size: 0.75em;
    letter-spacing: 2px;
}

.ticketTypeName{
    display: flex;
    gap: 5px;
    align-items: center;
}

.starCont{
    margin-top: 0.1rem;
    font-size: 0.8em;
    display: flex;
    gap:5px;
    align-items: center;
    justify-content: flex-start;
}


.eventCont h4{
   width: 100%;
   overflow-x: auto;
}

/* event detail modal */
.homeEventDetailModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.homeEventDetailsCont{
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    border-radius: 8px;
    overflow: hidden;
}


#homeModalImg{
    height: 100%;
    border-radius: none;
}

.closeHomeModal{
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: auto;
    height: 1.7rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
}

.modalSideA h4{
    font-weight: 600;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    color:white;
    position: absolute;
    bottom: 0.5rem;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modalSideA{
    width: 100%;
    height: 90%;
    position: relative;
    overflow: hidden;
}

.modalSideB{
    margin-top: 12px;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: translateY(0);
    font-size: 0.9em;
    transition: 1s ease;
}

.modalSideB h4{
    margin-bottom: -2.5rem;
    color: var(--pink);
}


.see-more-cont{
    text-align: center;
    font-weight: 600;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}


/* All events */
#events{
    width: 100%;
    height: auto;
    margin-top: 3rem;
    padding: 10px 3.5rem;

}

/* post event */
#post-event{
    width: 100%;
    height: auto;
    margin-top: 4rem;
}

.post-event-container{
    margin-top: 2rem;
    width: 100%;
    height: auto;
    background-image: url('../../assets/dave-lastovskiy-RygIdTavhkQ-unsplash.jpg');
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
}

.post-event-content{
    backdrop-filter: blur(2px);
    background-color: rgba(57, 39, 39, 0.04);
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.post-event-content h1{
    color: white;
    font-size: 2em;
}

.post-event-content h1 span{
    color:var(--pink);
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.588);
}

.post-event-content p{
    margin-top: 1rem;
    letter-spacing: 1px;
    text-shadow: 0 0 11px rgba(0, 0, 0, 0.9);
    font-size: 1em;
    width: 80%;
    line-height: 2rem;
}

.eventformCont{
    width: 100%;
    height: auto;
    padding: 2rem;
    background-color: var(--light-green);
    color: black;
    font-size: 0.8em;
    font-weight: bold;
}

.eventform{
    display: flex;
    width: 100%;
    gap: 1rem;
}

.formGroup{
    width: 50%;
}

.formGroup div{
    display: flex;
    flex-direction: column;
}

.formGroup input{
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #bfbebe;
}

.submitBtn{
    border: none;
    background-color: var(--pink);
    padding: 9px 18px;
    border-radius: 7px;
    color: white;
}

footer{
    padding: 3rem 2rem;
    height: auto;
    width: 100%;
    color: white;
    background-color: var(--pink);
    margin-top: 9rem;
}

footer img{
    width: 7rem;
    height: 4rem;
    margin-bottom: 1rem;
}

.footerGenCont{
    width: 60%;
    margin: auto;
}

.footerCont{
    width: 100%;
    display: flex;
   justify-content: space-between;
}

.footerCont h4{
    color: black;
}

.footerCont p a{
    color: white;
}



/* mobile responsive */

@media (max-width: 720px) {
    .navBg{
        position: relative;
        overflow: visible;
    }
    
    nav{
        overflow: visible;
        z-index: 100;
    }

    nav ul{
        position: absolute;
        top: 5rem;
        left: 0;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        padding: 0rem 0;
        transition: all 0.6s;
    }

    .cartCont{
        width: 100vw;
    }

    .cart-overlay{
        left: 0%;

    }

    .hide{
        display: initial;
    }

    .sideA{
        width: 100%;
    }

    
    .sideA h1{
        font-size: 2.8em;
    }

    .sideA p{
        width: 100%;
        margin: 1rem 0;
    }

    .sideA button{
        margin-top: 0;
    }

    #homeLanding{
        height: 80vh;
    }

    .homeCont{
        padding: 1rem;
    }

    .blur{
        width: 100%;
    }

    .searchCont{
        padding: .9rem;
        width: 95%;
    }

    .searchCont input{
        padding: 8px;
        border-radius: 11px;
    }

    #Latest-events{
        margin-top: 4rem;
        padding: 10px 1rem;
    }

    #events{
        padding: 10px 1rem;
    }

        
    .eventCont{
        height: 17rem;
        width: 9rem;
        background-color: white;
    }

    .eventCont img{
        height: 61%;
    }

    .eventCont h4{
        margin-top: 0.4rem;
        overflow-y: auto;
        width: 100%;
        height: 4rem;
    }
    
    .cartIcon-ticketType-cont{
        bottom: 31%;
    }

    .post-event-container{
        flex-direction: column;
    }

    .post-event-content p{
        width: 90%;
    }

    .eventformCont{
        padding: 1rem;
    }

    .footerGenCont{
        width: 100%;
        font-size: 0.8em;
    }

    .footer-column{
        margin-bottom: 1rem;
    }
    
    
}


@media (min-width: 720px) {
    
}

