:root{
    --dashboardBg: rgb(252, 225, 229);
}

.dashboardMainFrame{
    width: 100%;
    height: 100%;
    padding: 0 0.8rem;
}

/* heading styles */
.dashboardHeader{
    width: 100%;
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboardHeader img:nth-child(1){
    width: 5rem;
    height: 3rem;
}

.dashboardHeader div{
    display: flex;
    gap: 0.7rem;
    align-items: center;
}

.line{
    width: 90%;
    height: 1px;
    background-color: rgb(247, 119, 140);
    display: block;
    margin: auto;
    margin-top: 0.5rem;
    border-radius: 1px;
}

.dashboardHeading{
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0.5rem;
}

/* section and menu container */

.sections-menu-cont{
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.menu{
    position: fixed;
    width: 60%;
    height: 100vh;
    background-color: var(--dashboardBg);
    top: 0;
    left: 0;
    z-index: 5;
    border-radius: 8px;
    padding: 1rem;
    padding-top: 1.5rem;
}

.dashboardCloseBtn{
    text-align: right;
}

.menu-items{
    margin-top: 3rem;
}

.menu-item{
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 1.3rem;
}



.menu-item p:nth-child(1){
    /* padding: 2px; */
    border-radius: 4px;
    background-color:white;
    text-align: center;
    width: 1.6rem;
    height: 1.5rem;
}


/* sections */
.sectionsCont{
    width: 100%;
    height: auto;
    margin: 1.5rem 0;
}

/* wallet-tickets-cont */
.wallet-tickets-cont{
    display: flex;
    flex-direction: column;
}


/* wallet cont */
.walletCont{
    padding: 1.2rem 0.9rem;
    background-color: var(--dashboardBg) ;
    border-radius: 8px;
    margin-bottom: 2rem;
}
/* rgb(255, 220, 225) */

.walletCont p:nth-child(1){
    font-weight: 500;
}

.walletSubCont{
    background-color: white;
    height: 5rem;
    width: 100%;
    border-radius: 8px;
    margin: 1rem 0;
    margin-bottom: 0.3rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.iconCont{
    display: flex;
    gap: 5px;
    align-items: center;
}


/* tickets container */

.ticketsSubCont{
    background-color: white;
    height: 8rem;
    width: 100%;
    border-radius: 8px;
    margin: 1rem 0;
    margin-bottom: 0.3rem;
    padding: 0.7rem;
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.ticketsSubCont img{
    border-radius: 8px;
    height: 100%;
    width: 35%;
}

.ticketDetails{
    width: calc(65% - 5px);
    font-size: 0.8em;
    overflow-y: auto;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}


@media (max-width: 720px) {
    .menu{
        z-index: 5;
        opacity: 0;
        width: 50%;
        overflow: hidden;
        transition:ease-in 0.4s;
    }

    .showDashboardMenu{
        opacity: 1;
        z-index: 3;
        width: 60%;
        overflow: visible;
        transition: ease-out 0.4s;
    }
}


@media (min-width: 720px) {
.dashboardHeader img:nth-child(1){
    width: 7rem;
    height: 4rem;
}


/* wallet-tickets-cont */
.wallet-tickets-cont{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.top-flex{
    width: 50%;
    overflow-y: auto;
    height: 20rem;
}


.ticketsSubCont{
    height: 9rem;
}

.xx{
    height: 12rem;
    margin-top: 4rem;
}
    
.menu{
    position: relative;
    width: 20%;
    height: 100vh;
    background-color: var(--dashboardBg);
    top: 0;
    left: 0;
}


/* sections */
.sectionsCont{
    width: 80%;
    height: 100vh;
    margin: 1.5rem 0;
    overflow-y: auto;
}

}