
.formCont{
    width: 100%;
    height: 100vh;
    background-color: rgb(233, 218, 233);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formCont h1 {
    margin-bottom: 1.5rem;
    text-align: center;
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(to bottom right, rgb(122, 0, 122) 40%, rgb(179, 6, 179) 60%);
}
/* rgb(104, 4, 104) */
.signup-login-form{
    height: auto;
    background-color: white;
    padding: 2rem;
    width: 85%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.group{
    width: 100%;
}

.group input{
    margin-top: .5rem;
    width: 100%;
    padding: 1rem;
    border-radius: 19px;
    border: none;
    background-color: #ebeaea;
}


.group select{
    margin-top: .5rem;
    width: 100%;
    padding: 1rem;
    border-radius: 19px;
    border: none;
    background-color: #ebeaea;
}

.btnCont{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
 
.signup-login-form button{
    padding: 10px 25px;
    border: none;
    border-radius: 15px;
    margin-top: 1rem;
    letter-spacing: 1;
}

.signup-login-form button:nth-child(1){
    background-color: rgb(122, 0, 122);
    color: #ebeaea;
}

.signup-login-form button:nth-child(2){
    border: 1px solid rgb(122, 0, 122);
    color: rgb(122, 0, 122);
    background-color: white;
}

.submitBtn{
    padding: 10px 45px;
}

@media (min-width: 720px) {
    .signup-login-form{
        padding: 2rem 3rem;
        width: 40%;
    }
    
}