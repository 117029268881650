:root{
    --small-text: 0.9rem;
    --grey: #928b8b;
    --admin-bg: #fae1ed;
}

#admin-general-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    position: relative;
    position: fixed;
    background-color: var(--admin-bg);
}

.admin-side-bar{
    transform: translateX(-1rem);
    width: 65%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 1rem;
    opacity: 0;
    z-index: -5;
    transition: 0.5s ease-in;
    /* display: flex;
    align-items: center; */
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.3); */
}

.show-admin-sidebar{
    opacity: 1;
    z-index: 5;
   transform: translateX(0);
    transition: 0.5s ease-in;
}


.admin-side-bar p:nth-child(1){
    text-align: right;
}


.admin-side-bar ul{
    margin-top: 1.5rem;
}

.admin-side-bar ul li{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    color: #5e5757;
    margin-bottom: 1.2rem;
    font-size: var(--small-text);
    cursor: pointer;
}

.admin-main-area{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 10px 0.5rem;
    
}

.admin-title{
    border-radius: 10px;
    padding:16px 10px ;
    height: auto;
    width: 100%;
    background-color: white;
    margin-top: 1.3rem;
    letter-spacing: 3px;
}

.admin-title p{
    font-weight: bold;
}

.admin-content-area{
    border-radius: 10px;
    padding:16px 15px ;
    height: auto;
    width: 100%;
    background-color: white;
    margin-top: 1.3rem;
}


.admin-event{
    width: 100%;
    height: 6rem;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
}

.admin-event img{
    width: 30%;
    height: 100%;
    border-radius: 10px;
}

.admin-event-details{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.admin-event-details-date-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
}

.admin-event-details-date-cont b{
    color: #f6006d;
    cursor: pointer;
}

.admin-event-details-date-cont p{
    color: #8f8585;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.admin-event-details-date-cont svg{
    margin-top: 2px;
}


/* event details modal */
.admin-modal-overlay{
    z-index: 5;
    opacity: 1;
    transition: all 0.5s;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    /* position: absolute; */
    height: 100%;
}

.showAdminModal{
    z-index: 5;
    opacity: 1;
    transition: all 0.6s;
}

.admin-modal-overlay .admin-content{
    width: 80%;
    height: 90%;
    display: block;
    margin: auto;
    margin-top: 10%;
    background-color: white;
    border-radius: 10px;
    padding: 0.5rem 1.5rem 1.5rem;
    overflow-y: auto;
    font-size: var(--small-text);
}

.admin-modal-overlay .admin-content p:nth-child(1){
    width: 100%;
    border-bottom: 1px solid var(--grey);
    text-align: right;
    margin: auto ;
    padding: 0.8rem;
}

.admin-modal-overlay .admin-content h4{
    color: var(--pink);
    margin-top: .8rem;

}

/* post ny event section design */
#post-my-event img{
    width: 100%;
    height: 15rem;
    border-radius: 10px;
}

.post-my-event-cont{
    width: 100%;
    height: auto;
}

.post-my-event-details{
    width: 100%;
    display: flex;
    gap: 3px;
    flex-direction: row;
    font-size: var(--small-text);
    overflow-x: auto;
    border-bottom: 1px dashed var(--grey);
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}

.post-my-event-details div{
    width: 50%;
}

.post-my-event-details p{
    color: var(--grey);
}

/* all customers */
#all-customers-cont{
    overflow-x: auto;
}


#all-customers-cont table{
    width: auto;
    text-align: left;
    font-size: var(--small-text);
}

#all-customers-cont table tr td{
    min-width: 5rem;
    padding-right: 5px;
    color: var(--grey);
}

#all-customers-cont table tr th{
    min-width: 5rem;
    padding-right: 5px;
}


#all-customers-cont table tr td:nth-child(1){
    min-width: 2rem;
}

#all-customers-cont table tr th:nth-child(1){
    min-width: 2rem;
}

#all-customers-cont table button{
    background-color: var(--pink);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    outline: 2px solid var(--admin-bg);
    outline-offset: 2px;
}


/* tickets sold design */
#tickets-sold{
    overflow-x: auto;
}


#tickets-sold table{
    width: auto;
    text-align: left;
    font-size: var(--small-text);
}


#tickets-sold table tr td{
    padding-right: 5px;
    color: var(--grey);
}

#tickets-sold table tr th{
    min-width: 7rem;
    padding-right: 5px;
}


#tickets-sold table tr td:nth-child(1){
    min-width: 2rem;
}

#tickets-sold table tr th:nth-child(1){
    min-width: 2rem;
}

#tickets-sold table button{
    background-color: var(--pink);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    outline: 2px solid var(--admin-bg);
    outline-offset: 2px;
}

/* create event styling */
#create-event form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: black;
    font-size: var(--small-text);
    font-weight: 600;
}

.create-event-form-input-group{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    
    
}

.create-event-form-input-group div{
    gap: 5px;
    width: 100%;
}


.create-event-form-input-group div div{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.create-event-form-input-group div div input{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--grey);
    transition: 0.4s all;
}
.create-event-form-input-group div div input:focus{
    transform: scale(0.99);
    outline: none;
    border: 1px solid var(--pink);
}


.create-event-form-textarea{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.create-event-form-textarea button{
    padding: 10px;
    border: none;
    color: var(--pink);
    background-color: var(--admin-bg);
    margin-top: 1rem;
    border-radius: 7px;
    font-weight: 600;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#ticketPricesCont{
   display: grid;
   grid-template-columns: repeat(4, 24%);
   column-gap: 5px;
}


/* mobile design */
@media (max-width: 720px) {
    .admin-side-bar{
       border-right: 1px solid #dad4d4;
    }
}

@media (min-width: 720px) {
    .admin-side-bar{
        width: 20%;
        position: relative;
        top: 0;
        left: 0;
        transform: translateX(0rem);
        z-index: 2;
        opacity: 1;
    }

    .admin-main-area{
        width: 80%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 10px 2rem;
    }

        
    .admin-event{
        height: 8rem;
    }

    .admin-modal-overlay .admin-content{
        margin-top: 7%;
        height: 70%;
        width: 40%;
    }

    #all-customers-cont table tr td{
        padding: 7px;
     }

    #tickets-sold table tr td{
       padding: 7px;
    }

    #create-event form{
        flex-direction: row;
    }
    .create-event-form-input-group{
        flex-direction: row;
        width: 60%;
    }

    
    .create-event-form-input-group div{
        width: 50%;
    }

    .create-event-form-textarea{
        width: 40%;
    }
    
}